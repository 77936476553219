import { gql } from 'graphql-request';
import { client } from '../shopify';

const GET_COLLECTION_PRODUCTS = gql`
  query getCollectionProducts($collectionHandle: String!) {
    collectionByHandle(handle: $collectionHandle) {
      id
      title
      description
      products(first: 20) {
        edges {
          node {
            id
            title
            description
            priceRange {
              minVariantPrice {
                amount
                currencyCode
              }
            }
            images(first: 5) {
              edges {
                node {
                  src
                  altText
                }
              }
            }
           
            variants(first: 5) {
              edges {
                node {
                  id
                  title
                  price {
                    amount
                    currencyCode
                  }
                  availableForSale
                }
              }
            }
          }
        }
      }
    }
  }
`;

export async function getProductsByCollection(collectionHandle) {
  const response = await fetch(client.getStorefrontApiUrl(), {
    method: 'POST',
    headers: client.getPublicTokenHeaders(),
    body: JSON.stringify({
      query: GET_COLLECTION_PRODUCTS,
      variables: { collectionHandle },
    }),
  });

  const json = await response.json();

  return json?.data?.collectionByHandle?.products?.edges || [];
}
