import React, { useState, useEffect } from 'react';
import './Merchandise.css'
import MerchCard from './MerchCard'
import Shipping from '../../Shipping';
import { useProductContext } from '../../../Context/ProductContext';

export default function Merchandise() {

   const { merchandise, loadingProducts, productError } = useProductContext();

   if (loadingProducts) return <p>Loading merch...</p>;
   if (productError) return <p>Error loading merch: {productError.message}</p>;

  return (
    <>
    <Shipping />
    <div id='blogs'>
        <div style={{margin: '1rem auto'}}>
            <p id='get-the'>get the</p>
            <p id='merch-txt'>Merch</p>
        </div>
        <div id='merch-list'>

            {merchandise.map((merch, i) =>{
                return(
                    <MerchCard data={merch} key={i} id={merch.node.id}/>
                )
            }
        
            )}

        </div>
     
    </div>
    </>
  )
}
