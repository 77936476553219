import React, { useState, useEffect } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import { parseGid, useCart } from '@shopify/hydrogen-react';
import { getProduct } from '../../../Shopify/Queries/GetProduct';
import Shipping from '../../Shipping';
import { toast } from 'react-toastify';
import './Merchandise.css';

export default function MerchPage() {
    const { id } = useParams();
    const decodedId = decodeURIComponent(id);
    const { linesAdd } = useCart();

    const [product, setProduct] = useState(null);
    const [primaryImg, setPrimaryImg] = useState(0);
    const [variant, setVariant] = useState(null);

    const {setBasketOpen} = useOutletContext();
    
    useEffect(() => {
        getProduct(decodedId)
            .then((res) => setProduct(res.data.product))
            .catch((e) => console.error(e));
    }, [decodedId]);

    const handleAddToCart = () => {
  
      try {
        if(product.variants.edges.length === 1) {
          //Only one variant so doesn't need to be set
          linesAdd([{ merchandiseId: product.variants.edges[0].node.id, quantity: 1 }]);
          setBasketOpen(true)
        }
        else if(product.variants.edges.length > 1 && !variant){
          //No variant set
          toast.error("Please select a variant")
        } else if(product.variants.edges.length > 1){
          //Retrieve variant
          let selectedVariant = product.variants.edges.find(opt => opt.node.title === variant)
          linesAdd([{ merchandiseId: selectedVariant.node.id, quantity: 1 }]);
          setBasketOpen(true)
        } else {
          return
        }
      } catch (error) {
        console.error('Failed to add item to cart:', error);
      }
    };


    const returnPrice = () => {
        let selected = product.variants.edges.find(item => item.node.title === variant )
        return parseFloat(selected.node.price.amount).toFixed(2)
    }

    return (
        <div id='merch-page'>
            <Shipping />
            <div className='product-container'>
                {/* Left side - Image Gallery */}
                <div className='image-gallery'>
                    <img className='primary-img' src={product?.images?.edges[primaryImg]?.node.src} alt={product?.images?.edges[primaryImg]?.node.altText || product?.title} />
                    <div className='thumbnail-container'>
                        {product?.images?.edges.map((image, index) => (
                            <img 
                                key={index} 
                                src={image.node.src} 
                                alt={image.node.altText || product?.title} 
                                className={`thumbnail ${index === primaryImg ? 'active' : ''}`}
                                onClick={() => setPrimaryImg(index)}
                            />
                        ))}
                    </div>
                </div>
                
                {/* Right side - Product Details */}
                <div className='product-details'>
                    <h1>{product?.title}</h1>
                    <h4 style={{fontFamily: 'Futura-PT', fontSize: 24, margin: '10px 0'}}>£{!variant ? parseFloat(product?.variants.edges[0].node.price.amount).toFixed(2) : returnPrice() }</h4>
                    <div style={{display:'flex'}}>
                        {product?.variants.edges.map((opt, i) => {
                            return opt.node.title === 'Default Title' ? null : 
                                <div 
                                    key={i} 
                                    className={
                                    !opt.node.availableForSale ? 
                                    "not-for-sale" : 
                                    variant === opt.node.title ? 
                                    'size-container-selected' :'size-container'
                                    } 
                                    onClick={() => {
                                    !opt.node.availableForSale ? 
                                        setVariant("") : setVariant(opt.node.title)
                                    }
                                    }
                                >
                                    <p>{opt.node.title}</p>
                                </div>
                        })}
                        </div>
                    <button onClick={handleAddToCart} className='merch-btn'>Add to Cart</button>
                    <p className='product-description'>{product?.description}</p>
                </div>
            </div>
        </div>
    );
}
