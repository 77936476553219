import { useCart } from '@shopify/hydrogen-react';
import React, { useState } from 'react';
import { Link, useNavigate, useOutletContext } from 'react-router-dom'
import { toast } from 'react-toastify'
import left from '../../../Assets/icons/green-left.png'
import right from '../../../Assets/icons/green-right.png'

export default function MerchCard({ data, id }) {

  const [ image, setImage] = useState(0)
  const [variant, setVariant] = useState("")

  const navigate = useNavigate()

  const { linesAdd } = useCart();
  const {setBasketOpen} = useOutletContext();

  const handleAddToCart = () => {

    try {
      if(data.node.variants.edges.length === 1) {
        //Only one variant so doesn't need to be set
        linesAdd([{ merchandiseId: data.node.variants.edges[0].node.id, quantity: 1 }]);
        setBasketOpen(true)
      }
      else if(data.node.variants.edges.length > 1 && !variant){
        //No variant set
        toast.error("Please select a variant")
      } else if(data.node.variants.edges.length > 1){
        //Retrieve variant
        let selectedVariant = data.node.variants.edges.find(opt => opt.node.title === variant)
        linesAdd([{ merchandiseId: selectedVariant.node.id, quantity: 1 }]);
        setBasketOpen(true)
      } else {
        return
      }
    } catch (error) {
      console.error('Failed to add item to cart:', error);
    }
  };

  const allVariantsOutOfStock = data.node.variants.edges.every(
    (edge) => !edge.node.availableForSale
  );

  const handleLeft= (e) => {
    e.preventDefault()
    e.stopPropagation()
    if(image > 0) {
      setImage(prev => prev -1)
    }
  }

  const handleRight= (e) => {
    e.preventDefault()
    e.stopPropagation()
    if(image < (data.node.images.edges.length - 1)) {
      setImage(prev => prev + 1)
    }
  }

  return (
    <div className="merch-card">
     
        <div 
          style={
            {backgroundImage:`url("${data.node.images.edges[image].node.src}")`, cursor:'pointer', position:'relative'} 
          } 
          className="merch-card-image"  
          onClick={() => navigate(encodeURIComponent(`${encodeURIComponent(id)}`))}
        >
          <div className='merch-arrow' style={{left: 0}} onClick={handleLeft}>
            <img src={left}   alt='Thiago Tequila merchandise image select'/>
          </div>
          <div className='merch-arrow' style={{right: 0}} onClick={handleRight}>
            <img src={right}  alt='Thiago Tequila merchandise image select'/>
          </div>
        </div>
       
      <p style={{fontSize: 26, margin: '10px 0'}}>{data.node.title}</p>

      <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', flexWrap:'wrap'}}>
      {allVariantsOutOfStock ? (
        <button className="disabled-merch-btn" disabled>
          Out of Stock
        </button>
      ) : (
        <button className='merch-btn' onClick={(e) =>{ e.stopPropagation(); e.preventDefault(); handleAddToCart()}}>Add to Cart</button>
      )
    }

        <div style={{display:'flex'}}>
          {data.node.variants.edges.map((opt, i) => {
            return opt.node.title === 'Default Title' ? null : 
                  <div 
                    key={i} 
                    className={
                      !opt.node.availableForSale ? 
                      "not-for-sale" : 
                      variant === opt.node.title ? 
                      'size-container-selected' :'size-container'
                    } 
                    onClick={() => {
                      !opt.node.availableForSale ? 
                        setVariant("") : setVariant(opt.node.title)
                    }
                    }
                  >
                      <p>{opt.node.title}</p>
                  </div>
          })}
        </div>
        
      </div>

    </div>
  );
}