import React, { createContext, useState, useEffect, useContext } from 'react';
import { getAllProducts } from '../Shopify/Queries/GetAllProducts';
import { getProductsByCollection } from '../Shopify/Queries/GetProductsByCollection';

// Create the context
const ProductContext = createContext();

// Create the provider component
export const ProductGlobalContext = ({ children }) => {
  const [products, setProducts] = useState([]);
  const [merchandise, setMerchandise] = useState([]);
  const [loadingProducts, setLoading] = useState(true);
  const [productError, setError] = useState(null);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const productsData = await getAllProducts();
        // Usage
        const tequilaProducts = await getProductsByCollection('Tequila');
        const merchProducts = await getProductsByCollection('Merchandise');
        setProducts(tequilaProducts);
        setMerchandise(merchProducts)

      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  return (
    <ProductContext.Provider value={{ products, loadingProducts, productError, merchandise }}>
      {children}
    </ProductContext.Provider>
  );
};

// Custom hook for using the ProductContext
export const useProductContext = () => {
  return useContext(ProductContext);
};