import './App.css';
import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom'
import Basket from './components/PublicUI/Basket.js';
import SideNav from './components/PublicUI/SideNav.js';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from './components/PublicUI/Navbar.js';
import Footer from './components/PublicUI/Footer.js';
import BarTab from './components/PublicUI/BarTab.js';
import TagManager from 'react-gtm-module'
import ReactGA from "react-ga4";
import AgeVerify from './components/PublicUI/Modals/AgeVerify.js';
import DiscountModal from './components/PublicUI/Modals/DiscountModal.js';

function App() {

  const [basketOpen, setBasketOpen] = useState(false)
  const [open, setOpen] = useState(false)
  const [isVerified, setIsVerified] = useState(false);
  const [discountOpen, setDiscountOpen] = useState(false);

  const tagManagerArgs = {
    gtmId: 'GTM-NC78HQH3'
  }
  //Google analytics and tag manager
  TagManager.initialize(tagManagerArgs)
  ReactGA.initialize("G-VNPYXFJNVR");

    // Check if the user has already been verified when the app loads
    useEffect(() => {
        const storedVerification = localStorage.getItem('isVerified');
        if (storedVerification === "true") {
            setIsVerified(true);
        }
    }, []);

    useEffect(() => {
      // Check if 'receivedDiscount' is set to 'true' in localStorage
      const receivedDiscount = localStorage.getItem('receivedDiscount') === 'true';
  
      // Only set the timeout if receivedDiscount is false or not set
      if (!receivedDiscount) {
        const timer = setTimeout(() => {
          setDiscountOpen(true);
        }, 10000);
  
        // Cleanup the timer if the component unmounts
        return () => clearTimeout(timer);
      }
    }, []); // Empty dependency array to run only once on mount
  

    // Function to update the state when the user verifies their age
    const handleVerify = (verified) => {
        setIsVerified(verified);
    }

  return (
    <div className="App">
      {!isVerified && <AgeVerify onVerify={handleVerify}/>}
      {discountOpen && <DiscountModal setDiscountOpen={setDiscountOpen} />}

      <ToastContainer position='top-center'/>
      <Navbar setOpen={setOpen} open={open} basketOpen={basketOpen} setBasketOpen={setBasketOpen}/>

      <Basket basketOpen={basketOpen} setBasketOpen={setBasketOpen} />
      <SideNav open={open} setOpen={setOpen}/>
      
      <Outlet id='main' context={{setBasketOpen}}/>

      <BarTab />
      <Footer />

    </div>
  );
}

export default App;
