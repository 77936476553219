import { client } from '../shopify';

export const GET_PRODUCT_QUERY = `
 query getProduct($id: ID!) {
                product(id: $id) {
                  id
                  title
                  description
                  images(first: 5) {
                    edges {
                      node {
                        src
                        altText
                      }
                    }
                  }
                  variants(first: 10) {
                    edges {
                      node {
                        id
                        title
                        availableForSale
                        price {
                          amount
                          currencyCode
                        }
                      }
                    }
                  }
                }
              }

`;

export async function getProduct(id) {

  const response = await fetch(client.getStorefrontApiUrl(), {
    method: 'POST',
    headers: client.getPublicTokenHeaders({ buyerIp: '...' }),
    body: JSON.stringify({
      query: GET_PRODUCT_QUERY,
      variables: { id },
    }),
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  const data = await response.json();
  return data;
}
