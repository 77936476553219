import React from 'react'
import './Navbar.css'
import logo from '../../Assets/logo-new.webp'
import logoRed from '../../Assets/logo-new-red.png'
import { Link, useLocation, useMatch } from 'react-router-dom'
import basket from '../../Assets/icons/basket.webp'
import basketRed from '../../Assets/icons/red-basket.webp'

export default function Navbar({ open, setOpen, setBasketOpen }) {

  const location = useLocation()
  const { pathname } = location;
  const invertedNav = useMatch('/store/:id');


  return (
    <nav>
        <img id='mobile-basket-icon' src={(invertedNav && !open) ? basketRed : basket} onClick={() => setBasketOpen(true)} />

        <Link to={'/'}>
          <img 
            id='header-logo'
            src={(invertedNav && !open) ? logoRed : logo} 
            alt='Thiago Tequila UK Flavoured Tequila Logo'
            style={(pathname === '/policies/terms-of-service' || pathname === '/policies/privacy-policy') ? {filter: 'invert(1)'}: {}}
          />
        </Link>

        <div style={{display:'flex', alignItems:'center'}}>
          <img id='basket-icon' src={(invertedNav && !open) ? basketRed : basket} onClick={() => setBasketOpen(true)} />

          <div id='burger' className={open ? 'open-menu' : ''} onClick={() => setOpen(!open)}>
              <div className={(invertedNav && !open) ? 'red-burger-line' : 'burger-line'}/>
              <div className={(invertedNav && !open) ? 'red-burger-line' : 'burger-line'}/>
              <div className={(invertedNav && !open) ? 'red-burger-line' : 'burger-line'}/>
              <div className={(invertedNav && !open) ? 'red-burger-line' : 'burger-line'}/>
              <div className={(invertedNav && !open) ? 'red-burger-line' : 'burger-line'}/>
          </div>
        </div>
        
        
    </nav>
  )
}
